import React from 'react'
import './clients.css'

function clients() {
    return (
        <section id="clients" className="clients">
            <div className="container">

                <div className="section-title">
                    <h2>I've worked with</h2>
                    <p> My success is not only due to the quality of our work, it's down to attitude, my approach and the way i treat my clients.</p>
                </div>

                <div>
                    <div className="clients-list-group">
                        <div className="clients-list">
                            <div class="hexagon">
                                <a href="https://a2i.gov.bd/" target="_blank">
                                    <img src="assets/img/clients/a2i.png" alt="a2i" />
                                </a>
                            </div>
                        </div>
                        <div className="clients-list">
                            <div class="hexagon">
                                <a href="https://www.abbviepro.com/" target="_blank">
                                    <img src="assets/img/clients/abbvie.png" alt="abbvie" />
                                </a>
                            </div>
                        </div>
                        <div className="clients-list">
                            <div class="hexagon">
                                <a href="https://www.glpg.com/" target="_blank">
                                    <img src="assets/img/clients/galapagos.png" alt="galapagos" />
                                </a>
                            </div>
                        </div>
                        <div className="clients-list">
                            <div class="hexagon">
                                <a href="https://deshicommerce.com/" target="_blank">
                                    <img src="assets/img/clients/deshi-commerce.png" alt="Deshi Commerce" />
                                </a>
                            </div>
                        </div>
                        <div className="clients-list">
                            <div class="hexagon">
                                <a href="https://crownline.com/" target="_blank">
                                    <img src="assets/img/clients/crownline.png" alt="crownline" />
                                </a>
                            </div>
                        </div>
                        <div className="clients-list">
                            <div class="hexagon">
                                <a href="http://affiliate.lk/" target="_blank">
                                    <img src="assets/img/clients/affiliate.png" alt="Affiliate LK" />
                                </a>
                            </div>
                        </div>
                        <div className="clients-list">
                            <div class="hexagon">
                                <a href="https://mitsubishi-bd.com/" target="_blank">
                                    <img src="assets/img/clients/mitsubishi.png" alt="Mitsubishi Motors" />
                                </a>
                            </div>
                        </div>
                        <div className="clients-list">
                            <div class="hexagon">
                                <a href="http://www.shepbrowns.com" target="_blank">
                                    <img src="assets/img/clients/shepbrowns.png" alt="Shep Brown's Boat Basin" />
                                </a>
                            </div>
                        </div>
                        <div className="clients-list">
                            <div class="hexagon">
                                <a href="https://www.willship.com.au/" target="_blank">
                                    <img src="assets/img/clients/willship.png" alt="Willship" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default clients
