import React from 'react'
import './services.css'

function Services() {
    return (
        <section id="services" className="services">
            <div className="container">

                <div className="section-title">
                    <h2>Services</h2>
                    {/* <p>Mainly i provide services on frontend and UI/UX base work along with some other technical supports.</p> */}
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                        <div className="icon"><i className="icofont-code"></i></div>
                        <h4 className="title"><a href="">UI Development</a></h4>
                        <p className="description">I am expart in UI development for both web and mobile app using w3c standard, clean code & web accessiblity. </p>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon"><i className="icofont-responsive"></i></div>
                        <h4 className="title"><a href="">UI/UX</a></h4>
                        <p className="description">With sharp eye for UI/UX, i am serving many local and global clients application with great user experiance report & client satisfaction.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                        <div className="icon"><i className="icofont-site-map"></i></div>
                        <h4 className="title"><a href="">Project Management</a></h4>
                        <p className="description">If you want to build any great project but having touble with technical aspect or project management support, feel free to knock me.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                        <div className="icon"><i className="icofont-light-bulb"></i></div>
                        <h4 className="title"><a href="">Branding</a></h4>
                        <p className="description">I believe in first impression concept. Now a days baranding is a great concern for any business. To boost up your business, i am providing branding support.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                        <div className="icon"><i className="icofont-paint"></i></div>
                        <h4 className="title"><a href="">Graphics Design</a></h4>
                        <p className="description">I am very much passionate about art and user flexiblity. I like to combine people demand with the art of design using Adobe Photoshop & Adobe Illustartor.</p>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                        <div className="icon"><i className="icofont-tasks-alt"></i></div>
                        <h4 className="title"><a href="">Technical Support</a></h4>
                        <p className="description">I am ready to provide any kind of technical support </p>
                    </div> */}
                </div>

            </div>
        </section>
    )
}

export default Services
