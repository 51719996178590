import React, { useRef } from 'react'
import './contact.css'
import emailjs from 'emailjs-com';

function Contact() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_4sqsfx2', 'template_q672o1k', form.current, 'user_aBew1yJtXvU3JjV72Mex4')
        .then((result) => {
            console.log(result.text);
            //return(result.text == 'OK' ? 'Your message has been sent. Thank you!' : '')
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    };

    return (
        
        <section id="contact" className="contact">
            <div className="container">

                <div className="section-title">
                <h2>Contact</h2>
                <p>Have an exciting project where you need some help?<br/>Send me over a message, and let's chat.</p>
                </div>

                <div className="row" data-aos="fade-in">

                    <div className="col-lg-5 d-flex align-items-stretch">
                        <div className="info">
                            <div className="address">
                                <i className="icofont-google-map"></i>
                                <h4>Location:</h4>
                                <p>Khilkhet, Dhaka-1229, Bangladesh</p>
                            </div>

                            <div className="email">
                                <i className="icofont-envelope"></i>
                                <h4>Email:</h4>
                                <p>info@shahriage.com</p>
                            </div>

                            <div className="phone">
                                <i className="icofont-phone"></i>
                                <h4>Call:</h4>
                                <p>+8801619411666</p>
                            </div>

                            <iframe id="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7299.284304535436!2d90.42202597666821!3d23.831320788198088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c6422bc83d21%3A0x3a1bc96ce9f8ad8b!2sKhilkhet%2C%20Dhaka!5e0!3m2!1sen!2sbd!4v1634763727994!5m2!1sen!2sbd" loading="lazy" allowFullScreen></iframe>
                        </div>

                    </div>

                    <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                        {/* <form action="forms/contact.php" method="post"> */}
                        <form ref={form} onSubmit={sendEmail}  role="form" className="php-email-form">
                            <div className="form row">
                                <div className="form-group col-md-6">
                                <label htmlFor="name">Your Name</label>
                                <input type="text" name="name" className="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                <div className="validate"></div>
                                </div>
                                <div className="form-group col-md-6">
                                <label htmlFor="name">Your Email</label>
                                <input type="email" className="form-control" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />
                                <div className="validate"></div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">Subject</label>
                                <input type="text" className="form-control" name="subject" id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                                <div className="validate"></div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">Message</label>
                                <textarea className="form-control" name="message" rows="10" data-rule="required" data-msg="Please write something for us"></textarea>
                                <div className="validate"></div>
                            </div>
                            <div className="mb-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div className="text-center"><button type="submit">Send Message</button></div>
                        </form>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default Contact
