import React from 'react'
import './resume.css'

function Resume() {
    return (
        <section id="resume" className="resume">
            <div className="container">

                <div className="section-title">
                    <h2>Timeline</h2>
                    <p>Here is the short timeline reflecting my professional & educational history.</p>
                </div>

                <div className="row">
                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                        <h3 className="resume-title">Professional Experience</h3>
                        <div className="resume-item">
                            <h4>UI Engineer</h4>
                            <h5>Dec 2018 - Present</h5>
                            <p className="text-primary"><b>Brain Station-23</b></p>
                            <ul>
                                <li>Working in AEM (Adobe Experience Manager) environment.</li>
                                <li>Understand business & design from client side.</li>
                                <li>Task distribution to junior developers.</li>
                                <li>Build reusable component base UI</li>
                                <li>Pixel perfect dessign & SEO based Semantic Markup</li>
                                <li>Web accessibility, usability & Cross Browser Compatibility IE, FF, Chrome, Safari</li>
                                <li>Convert React components to AEM components.</li>
                                <li>React, Storybook React, SASS, CSS Atomic Design, BEM, Gulp, Git, Bootstrap, Hugo</li>
                            </ul>
                        </div>
                        <div className="resume-item">
                            <h4>FRONTEND DEVELOPER</h4>
                            <h5>Nov 2015 - Nov 2018</h5>
                            <p className="text-primary"><b>Blubird Interactive Ltd.</b></p>
                            <ul>
                                <li>Build responsive websites/web applications from PSD, AI Or PDF</li>
                                <li>Pixel perfect design with cross browser support</li>
                                <li>Modication according to change request by the clients.</li>
                                <li>Web application, Mobile APP, SEO, Cross-browser compatibility & Responsive design</li>
                                <li>Frontend development using JQuery, HTML5, CSS3, SASS, Atomic Design, Gulp, Git, Bootstrap, Foundation, Material Design & JavaScript</li>
                            </ul>
                        </div>

                        <div className="resume-item">
                            <h4>FRONTEND DEVELOPER</h4>
                            <h5>Apr 2014 - Oct 2015</h5>
                            <p className="text-primary"><b>Index Group</b></p>
                            <ul>
                                <li>Implemented visual and interactive elements that users can engage with through their web browser when using a web application.</li>
                                <li>HTML template development, PSD to HTML conversion, Responsive web and Email template development.</li>
                                <li>UI development using HTML, CSS, jQuery, Bootstrap 3, Foundation 5</li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-6" data-aos="fade-up">
                        <h3 className="resume-title">Education</h3>
                        <div className="resume-item">
                            <h4>BSc Engineering <br/><small>Computer Science &amp; Information Technology</small></h4>
                            <h5>2011 - 2015</h5>
                            <p><em>University of South Asia, Bangladesh</em></p>
                            <p>I have learnt Computer Fundamentals & Prog-Technique, Data Structure, Algorithm, Simulation & Modeling, Theory of Computing, Computer Architecture and Multimedia System Design etc. related to this course.</p>
                        </div>

                        <br></br>

                        <h3 className="resume-title">Certification</h3>
                        <div className="resume-item">
                            <h4>Microsoft Certification (Exam 98-383)</h4>
                            <h5>2019</h5>
                            <p><em>MTA: Introduction to Programming Using HTML and CSS</em></p>
                            <p>For those candidates who have hands-on experience with HTML and CSS, familiar with their features and capabilities, and understand how to write, debug, and maintain well-formed HTML and CSS code.</p>
                            <p><a href="https://www.youracclaim.com/badges/ceb9e3d3-2263-46f0-82d2-d1838931b491/public_url" target="_blank">Here's the link</a></p>
                            <img className="img-fluid w-50" src="assets/img/certificate.png" alt="Certificate"/>
                        </div>
                        <div className="resume-item">
                            <h4>Foundations of User Experience (UX) Design</h4>
                            <h5>2021</h5>
                            <p>Understand foundational concepts in UX design, such as user-centered design, the design process, accessibility, and equity-focused design.</p>
                            <p><a href="https://coursera.org/share/d7d613c74eac1abd5fa6fdb99e6ea08a" target="_blank">Here's the link</a></p>
                            <img className="img-fluid w-50" src="assets/img/google-ux-design.png" alt="Google UX Certificate"/>
                        </div>
                        
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Resume
