import React, {useState} from 'react'
import './header.css'

function Header() {

    const values = [
        { id: 0, href: "#hero-banner", class:"bx bx-home", text:"Home"},
        { id: 1, href: "#about", class:"bx bx-user", text:"About"},
        { id: 2, href: "#resume", class:"bx bx-file-blank", text:"Resume"},
        { id: 3, href: "#portfolio", class:"bx bx-book-content", text:"Portfolio"},
        { id: 4, href: "#services", class:"bx bx-server", text:"services"},
        { id: 5, href: "#clients", class:"bx bx-smile", text:"Happy Clients"},
        { id: 6, href: "#contact", class:"bx bx-envelope", text:"Contact"}
    ];
    const [activeId, setActiveId] = useState(0);
    

    return (
        <div className="header-section">
            <button type="button" className="mobile-nav-toggle d-xl-none"><i className="icofont-navigation-menu"></i></button>

            <header id="header">
                <div className="d-flex flex-column">
                    <div className="profile mt-4">
                        <img src="assets/img/Syed_Shah_Riage.jpg" alt="" className="img-fluid rounded-circle" />
                        <h1 className="text-light"><a href="index.html">Syed Shah Riage</a></h1>
                        <div className="social-links mt-3 text-center">
                            <a target="_blank" href="https://www.facebook.com/syedshahriage/" className="facebook"><i className="bx bxl-facebook"></i></a>
                            <a target="_blank" href="https://join.skype.com/invite/wfcUbODUrAVV" className="google-plus"><i className="bx bxl-skype"></i></a>
                            <a target="_blank" href="https://www.linkedin.com/in/syed-shah-riage-37652890" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                        </div>
                    </div>

                    <nav className="nav-menu mt-4">
                        <ul>
                            {values.map((val) => {
                                return(
                                    <li id={val.id} onClick={()=> setActiveId(val.id)} className={activeId === val.id ? "active" : "inactive"}>
                                        <a href={val.href}><i className={val.class}></i> <span>{val.text}</span></a>
                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                    <button type="button" className="mobile-nav-toggle d-xl-none"><i className="icofont-navigation-menu"></i></button>
                </div>
            </header>
        </div>
    )
}

export default Header
