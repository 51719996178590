import React, {useState, useEffect} from 'react'
import './testimonials.css'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import ItemsData from './data' 


function Testimonials() {

    const [items, setItems] = useState(ItemsData);

    // useEffect(() => {
    //     // const result = await axios(
    //     //   'https://hn.algolia.com/api/v1/search?query=redux',
    //     // );
    
    //     setItems(Data);
    //   }, []);

    const options = {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 3,
            },
        },
        nav: false,
        autoplay: true,
        slideBy: 1,
        dots: true,
        dotsEach: false,
        dotData: false
    };

    console.log(items);

    return (
        <section id="testimonials" className="testimonials section-bg">
            <div className="container">

                <div className="section-title">
                    <h2>Testimonials</h2>
                    <p>Here is some lovely words from my clients and colleagues describing their experiance and satisfaction level.</p>
                </div>

                <OwlCarousel className='owl-theme' {...options} >
                    {items.map((elem) => {
                        const {name, designation, Office_name, image_url, testimonial} = elem;
                        return(
                            <div className="testimonial-item" data-aos="fade-up">
                                <p>
                                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    {testimonial}
                                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                </p>
                                <img src={image_url} className="testimonial-img" alt="" />
                                <h3>{name}</h3>
                                <h4>{designation}</h4>
                                <h6><small>{Office_name}</small></h6>
                            </div>
                        )
                    })} 
                </OwlCarousel>
            </div>
        </section>
    )
}

export default Testimonials
