const Menu =
    [
        {
            "id": 0,
            "category": "web",
            "image" : [
                {
                    "image_id" : 0,
                    "image_url" : "assets/img/portfolio/mymentum.jpg",
                    "is_featured" : true
                },
                {
                    "image_id" : 1,
                    "image_url" : "assets/img/portfolio/mymentum-01.jpg",
                    "is_featured" : false
                }
            ],
            "title": "Mymentum",
            "link": "https://www-stage.glpg-mymentum.com",
            "technology": "React, Storybook React, jQuery, HTML5, CSS3, SCSS, BEM, Atomic Design css, Bootstrap, Gulp, Git",
            "technical_desc": "Build reusable component base UI according to client provided design using HTML5, CSS3, SCSS(SMA css), Boostrap 4, Gulp, JavaScript and following BEM & Atomic Design concept in Storybook React. And ensure all components tablet and mobile view with cross browser capablity and maintain high standard of coding.",
            "description": "Mymentum a medical care websitite that focuses on rheumatoid arthritis (RA) and all its treatments policies. There is a lot of good information about RA out there. You can learn all about what can trigger it, what it is like to live with and how it is diagnosed from numerous sources, such as: Your doctor / nurse, Your physiotherapist or occupational therapist, YouTube videos Medical websites, Charity organizations, Other people with RA and Internet searches etc."
        },
        {
            "id": 1,
            "category": "web",
            "image" : [
                {
                    "image_id" : 0,
                    "image_url" : "assets/img/portfolio/hcp-portal.jpg",
                    "is_featured" : true
                },
                {
                    "image_id" : 1,
                    "image_url" : "assets/img/portfolio/hcp-portal-01.jpg",
                    "is_featured" : false
                }
            ],
            "title": "HCP Portal",
            "link": "https://www.galapagoshealth.com",
            "technology": "React, Storybook React, jQuery, HTML5, CSS3, SCSS, BEM, Atomic Design css, Bootstrap, Gulp, Git",
            "technical_desc": "Build reusable component base UI according to client provided design in invision using HTML5, CSS3, SCSS(SMA css), Boostrap 4, Gulp, JavaScript and following BEM & Atomic Design concept in Storybook React. Also ensure all components tablet and mobile view with cross browser capablity and maintain high standard of coding.",
            "description": "Galapagos is a clinical-stage biotechnology company, specialized in the discovery and development of small molecule medicines with novel modes of action. "
        },
        {
        "id": 2,
        "category": "web",
        "image" : [
            {
                "image_id" : 0,
                "image_url" : "assets/img/portfolio/jyseleca.png",
                "is_featured" : true
            },
            {
                "image_id" : 1,
                "image_url" : "assets/img/portfolio/jyseleca-01.png",
                "is_featured" : false
            }
        ],
        "title": "Jyseleca",
        "link": "https://www.jyseleca.nl",
        "technology": "React, Storybook React, jQuery, HTML5, CSS3, SCSS, BEM, Atomic Design css, Bootstrap, Gulp, Git",
        "technical_desc": "Build reusable component base UI according to client provided design in invision using HTML5, CSS3, SCSS(SMA css), Boostrap 4, Gulp, JavaScript and following BEM & Atomic Design concept in Storybook React. Also ensure all components tablet and mobile view with cross browser capablity and maintain high standard of coding.",
        "description": "This Portal is for Galapagos Jyseleca a pioneering biotechnology company. This is a information base for a specific medicine called Jyseleca where a user can get all information like how to take, side effects,stores etc. This website is launched in several languages."
        },
        {
            "id": 3,
            "category": "web",
            "image" : [
                {
                    "image_id" : 0,
                    "image_url" : "assets/img/portfolio/clinical-trials.jpg",
                    "is_featured" : true
                },
                {
                    "image_id" : 1,
                    "image_url" : "assets/img/portfolio/clinical-trials.jpg",
                    "is_featured" : false
                }
            ],
            "title": "Clinical Trials",
            "link": "https://www.clinicaltrials-glpg.com",
            "technology": "React, Storybook React, jQuery, HTML5, CSS3, SCSS, Atomic Design css, BEM, Bootstrap, Gulp, Git",
            "technical_desc": "Build component base & reuseable UI according to client design following Invision design and ensure pixel perfect deign with Web accessibility & usability, cross browser support and Responsive Design.",
            "description": "The website is built on AEM to provide dynamic user experience. This Portal is for Galapagos Clinical trials a pioneering biotechnology company. Clinical trials are studies that can help us develop new treatments for medical conditions and therefore improve peoples health. This site is a comprehensive resource for patients, careers and healthcare professionals, It uses features like Dynamic Template, Experience Fragments etc and it won the award for net and clean design & presentation in medical field in UK."
        },
        {
            "id": 4,
            "category": "web",
            "image" : [
                {
                    "image_id" : 0,
                    "image_url" : "assets/img/portfolio/let-me-be-clear.jpg",
                    "is_featured" : true
                },
                {
                    "image_id" : 1,
                    "image_url" : "assets/img/portfolio/let-me-be-clear.jpg",
                    "is_featured" : false
                }
            ],
            "title": "Let Me Be Clear",
            "link": "https://www.let-me-be-clear.com",
            "technology": "React, Storybook React, jQuery, HTML5, CSS3, SCSS, Atomic Design css, BEM, Bootstrap, Gulp, Git",
            "technical_desc": "Build reusable component base UI according to client provided design in invision using HTML5, CSS3, SCSS(SMA css), Boostrap 4, Gulp, JavaScript and following BEM & Atomic Design concept in Storybook React. Also ensure all components tablet and mobile view with cross browser capablity and maintain high standard of coding.",
            "description": "This web application is a skin diseases related headlth portal where a user can get in touch a doctor by telling his history and get prescribed. Also a can find same cases from blogs and other shared history."
        },
        {
            "id": 5,
            "category": "web",
            "image" : [
                {
                    "image_id" : 0,
                    "image_url" : "assets/img/portfolio/info-center.jpg",
                    "is_featured" : true
                },
                {
                    "image_id" : 1,
                    "image_url" : "assets/img/portfolio/info-center.jpg",
                    "is_featured" : false
                }
            ],
            "title": "Info Center",
            "link": "https://www.infocenter-derma.de",
            "technology": "React, Storybook React, jQuery, HTML5, CSS3, SCSS, Atomic Design css, BEM, Bootstrap, Gulp, Git",
            "technical_desc": "Build reusable component base UI according to client provided design in invision using HTML5, CSS3, SCSS(SMA css), Boostrap, Gulp, JavaScript and following BEM & Atomic Design concept. Also ensure all components tablet and mobile view with cross browser capablity and maintain high standard of coding.  The main challenge in this project wes multiple theming support with the same structure.",
            "description": "A free medical online learning source for healthcare professionals. It offers interactive eLearning, video tutorials, and services suchas disease measurement tools, a literature search, an event calendar,publications, country-specific content (identifiable by the use of common shortcuts in parentheses) and much more."
        },
        {
            "id": 6,
            "category": "web",
            "image" : [
                {
                    "image_id" : 0,
                    "image_url" : "assets/img/portfolio/events.jpg",
                    "is_featured" : true
                },
                {
                    "image_id" : 1,
                    "image_url" : "assets/img/portfolio/events.jpg",
                    "is_featured" : false
                }
            ],
            "title": "Inflammation GLPG UK",
            "link": "https://inflammation.glpg.uk",
            "technology": "React, Storybook React, jQuery, HTML5, CSS3, SCSS, Atomic Design css, BEM, Bootstrap, Gulp, Git",
            "technical_desc": "Build reusable component base UI according to client provided design in invision using HTML5, CSS3, SCSS(SMA css), Boostrap 4, Gulp, JavaScript and following BEM & Atomic Design concept in Storybook React. Also ensure all components tablet and mobile view with cross browser capablity and maintain high standard of coding.",
            "description": "This website is for Galapagos a pioneering biotechnology company, focused on making a difference to people with inflammatory diseases. The Galapagos IBD community website aims to provide healthcare professionals in the IBD community with a dynamic hub of engaging and up to-date IBD content."
        },
        {
            "id": 7,
            "category": "web",
            "image" : [
                {
                    "image_id" : 0,
                    "image_url" : "assets/img/portfolio/affiliate.jpg",
                    "is_featured" : true
                },
                {
                    "image_id" : 1,
                    "image_url" : "assets/img/portfolio/affiliate.jpg",
                    "is_featured" : false
                }
            ],
            "title": "Affiliate LK",
            "link": "https://syedshahriaz.github.io/affiliate",
            "technology": "HTML5, CSS3, SCSS, jQuery, Atomic Design css, Bootstrap, Gulp & Git",
            "technical_desc": "Build reusable component base UI according to client provided design in PSD using HTML5, CSS3, SCSS, Boostrap, Gulp, JavaScript and following Atomic Design concept. Also ensure all components tablet and mobile view with cross browser capablity and maintain high standard of coding.",
            "description": "Affiliate LK is for a client from Sri-Lanka for digital marketing purpose. Got a great feedback from client for delivering this prject onsharp dealine and pixel perfect and cross browser support."
        },
        {
        "id": 8,
        "category": "web",
        "image" : [
            {
                "image_id" : 0,
                "image_url" : "assets/img/portfolio/abbvie-pro.jpg",
                "is_featured" : true
            },
            {
                "image_id" : 1,
                "image_url" : "assets/img/portfolio/abbvie-pro.jpg",
                "is_featured" : false
            }
        ],
            "title": "Abbvie pro",
            "link": "https://www.abbviepro.com",
            "technology": "HTML5, CSS3, SCSS, Atomic Design css, BEM, Bootstrap, jQuery, Gulp, Git",
            "technical_desc": "Build reusable component base UI according to client provided design in invision using HTML5, CSS3, SCSS(SMA css), Boostrap, Gulp, JavaScript and following BEM & Atomic Design concept. Also ensure all components tablet and mobile view with cross browser capablity and maintain high standard of coding.",
            "description": "The website is built on AEM to provide dynamic user experience. It is a protected site for health care professionals to showcase pharmaceutical products. It uses features like Multi Site Management (MSM), Dynamic Template, Experience Fragments. "
        },
        {
            "id": 9,
            "category": "app",
            "image" : [
                {
                    "image_id" : 0,
                    "image_url" : "assets/img/portfolio/Crownline-boat-app.jpg",
                    "is_featured" : true
                },
                {
                    "image_id" : 1,
                    "image_url" : "assets/img/portfolio/Crownline-boat.jpg",
                    "is_featured" : false
                }
            ],
            "title": "Crownline Boat",
            "link": "",
            "technology": "HTML5, CSS3, CSS, Ionic Framework, Angular js, javascript, Git",
            "technical_desc": "Build app UI according to client provided design in using HTML, CSS, Ionic Framework, Angular js, javascript, Git. Also ensure all types of cross browser capablity and maintain high standard of coding.",
            "description": "This hybride app is built for a boat builder company. Many exciting featers are in this app like pre made boad sell, custom boat making option, ecommerce cart, payment gatewey etc."
        },
        // {
        //     "id": 10,
        //     "category": "card",
        //     "image" : [
        //         {
        //             "image_id" : 0,
        //             "image_url" : "assets/img/portfolio/portfolio-9.jpg",
        //             "is_featured" : true
        //         },
        //         {
        //             "image_id" : 1,
        //             "image_url" : "assets/img/portfolio/portfolio-6=3.jpg",
        //             "is_featured" : false
        //         }
        //     ],
        //     "title": "nesciunt iure omnis dolorem tempora et accusantium",
        //     "technology": "React, Storybook React, jQuery, HTML5, CSS3, SCSS, BEM, Bootstrap, Gulp, Git",
        //     "description": "consectetur animi nesciunt iure dolore\nenim quia ad\nveniam autem ut quam aut nobis\net est aut quod aut provident voluptas autem voluptas"
        // },
        // {
        //     "id": 11,
        //     "category": "card",
        //     "image" : [
        //         {
        //             "image_id" : 0,
        //             "image_url" : "assets/img/portfolio/portfolio-2.jpg",
        //             "is_featured" : true
        //         },
        //         {
        //             "image_id" : 1,
        //             "image_url" : "assets/img/portfolio/portfolio-1.jpg",
        //             "is_featured" : false
        //         }
        //     ],
        //     "title": "optio molestias id quia eum",
        //     "technology": "React, Storybook React, jQuery, HTML5, CSS3, SCSS, BEM, Bootstrap, Gulp, Git",
        //     "description": "quo et expedita modi cum officia vel magni\ndoloribus qui repudiandae\nvero nisi sit\nquos veniam quod sed accusamus veritatis error"
        // }
    ]


    export default Menu