import React from 'react'
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import About from "./components/about/about";
import Facts from "./components/facts/facts";
import Skills from "./components/skills/skills";
import Resume from "./components/resume/resume";
import Portfolio from "./components/portfolio/portfolio";
import Services from "./components/services/services";
import Testimonials from "./components/testimonials/testimonials";
import HeroBanner from "./components/hero-banner/hero-banner";
import Clients from "./components/clients/clients";
import Contact from "./components/contact/contact";
import Icofont from 'react-icofont';

function App() {
  return (
    <div className="App">
      <Header/>
      <HeroBanner/>
      <main id="main">
        <About/>
        <Facts/>
        <Skills/>
        <Resume/>
        <Portfolio/>
        <Services/>
        <Clients/>
        <Testimonials/>
        <Contact/>
      </main>
      <Footer/>
      
    </div>
  );
}

export default App;
