import React from 'react'
import './about.css'

function About() {
    return (
        <section id="about" className="about">
            <div className="container">

                <div className="section-title">
                    <h2>About</h2>
                    <p>I'm a Front-end Engineer based in Dhaka, Bangladesh. With a strong technical skill-set, attention to detail and 7 years of experience, i like to combine the art of design with the art of programming and elevate user experience to the next level by utilizing all my skills, knowledge, abilities and experience, management and leadership responsibilities.</p>
                </div>

                <div className="row">
                    <div className="col-lg-3" data-aos="fade-right">
                        <img src="assets/img/Syed_Shah_Riage.jpg" className="img-fluid" alt="" />
                    </div>

                    <div className="col-lg-9" data-aos="fade-left">
                        <div className="pt-4 pt-lg-0 content vt-center-text">
                            <h3>Personal Details</h3>
                            <p className="font-italic">Here is some personal informations about myself.</p>
                            <div className="row">
                                <div className="col-lg-6">
                                    <ul>
                                    <li><i className="icofont-rounded-right"></i> <strong>Birthday:</strong> 16 November, 1991</li>
                                    <li><i className="icofont-rounded-right"></i> <strong>Website:</strong> www.shahriage.com</li>
                                    <li><i className="icofont-rounded-right"></i> <strong>Phone:</strong> +8801619411666</li>
                                    <li><i className="icofont-rounded-right"></i> <strong>City:</strong> Dhaka, Bangladesh</li>
                                    </ul>
                                </div>
                                <div className="col-lg-6">
                                    <ul>
                                    <li><i className="icofont-rounded-right"></i> <strong>Age:</strong> 30</li>
                                    <li><i className="icofont-rounded-right"></i> <strong>Degree:</strong> BSC in CSIT</li>
                                    <li><i className="icofont-rounded-right"></i> <strong>Email:</strong> shahriaz2012@gmail.com</li>
                                    <li><i className="icofont-rounded-right"></i> <strong>Ready to Hire:</strong> Available</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default About
