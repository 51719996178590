import React from 'react'
import './footer.css'

function Footer() {
    return (
        <footer id="footer">
            <div className="container">
            <div className="copyright">
                &copy; Copyright <strong><span>iPortfolio</span></strong>
            </div>
            <div className="credits">
                Designed by <a href="#">Syed Shah Riage</a>
            </div>
            </div>
        </footer>
    )
}

export default Footer
