import React, {useState} from 'react'
import './portfolio.css'
import Menu from './data' 
import PortfolioDetails from './portfolio-details';

function Portfolio() {

    const [items, setItems] = useState(Menu);
    const [modalShow, setModalShow] = useState(false);
    const [dataID, setDataID] = useState(0);
    
    const filterItem = (categItem) => {
        const updatedItems = Menu.filter((curElem) => {
            return curElem.category === categItem;
        });
        setItems(updatedItems);
    }

    const clickHandler = (id) => {
        setModalShow(true); 
        setDataID(id);
    }

    return (
        <section id="portfolio" className="portfolio section-bg" style={{backgroundColor : '#fef4e8'}}>
            <div className="container">

                <div className="section-title">
                    <h2>Portfolio</h2>
                    <p>Below you can see some projects I've been working on lately. I divide each project into four follow-up areas: comprehensive research, planning, development & testing. This process allows me to create great product with client goals in mind.</p>
                </div>

                <div className="row" data-aos="fade-up">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <ul id="portfolio-flters">
                            <li id="01" className="filter-active" onClick={() => setItems(Menu) }>All</li>
                            <li id="02" onClick={() => filterItem('web')}>Web</li>
                            <li id="03" onClick={() => filterItem('app')}>App</li>
                            {/* <li onClick={() => filterItem('card')}>Card</li> */}
                        </ul>
                    </div>
                </div>

                <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="100">
                    {items.map((elem) => {
                        const {id, title, description, image} = elem;
                        return(
                            <div className="col-lg-4 col-md-6 portfolio-item filter-app" key={id} id={id}>
                                <div className="portfolio-wrap">
                                    {image.map((item) => {
                                        const{image_id, image_url, is_featured} = item
                                        return(
                                            <>
                                                {is_featured && <img id={image_id} src={image_url} className="img-fluid" alt="" />}
                                            </>
                                        )
                                    })}
                                    <div className="portfolio-links">
                                        <a onClick={() => clickHandler(id)} title="More Details">
                                            <i className='bx bx-search-alt'></i>
                                            <h5>{title}</h5>
                                        </a>                                    
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    {modalShow && 
                        <PortfolioDetails dataIdNo={dataID} closeModal={setModalShow}/>
                    } 
                </div>
            </div>
        </section>
    )
}

export default Portfolio
