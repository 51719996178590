import React, {useState} from 'react'
import './skills.css'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

function Skills() {
    const [viewport, setViewport] = useState(false);

    const onVisibilityChange = isVisible => {
        if (isVisible) {
            setViewport(true);
        }
    }
    return (
        <section id="skills" className="skills">
            <div className="container">

                <div className="section-title">
                    <h2>Skills</h2>
                    <p>I believe in creating quality solutions by insightful application of software engineering, UX design, and project management principles. I have a deep passion for technology and strive to obtain a continued and deep knowledge of what I see as the beautiful journey.</p>
                </div>

                <div className="row skills-content">

                    <div className="col-lg-6" data-aos="fade-up">

                        <div className="progress">
                            <span className="skill">HTML 
                                <i className="val">
                                    <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                                        <CountUp start={0} end={viewport ? 90 : 0} duration={2} />
                                    </VisibilitySensor>%
                                </i>
                            </span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" style={{width: '90'+'%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div className="progress">
                            <span className="skill">CSS/SCSS/BEM/Atomic Design 
                                <i className="val">
                                    <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                                        <CountUp start={0} end={viewport ? 90 : 0} duration={2} />
                                    </VisibilitySensor>%
                                </i>
                            </span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" style={{width: '90'+'%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div className="progress">
                            <span className="skill">Adobe Experience Manager 
                                <i className="val">
                                    <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                                        <CountUp start={0} end={viewport ? 90 : 0} duration={2} />
                                    </VisibilitySensor>%
                                </i>
                            </span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" style={{width: '70'+'%'}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div className="progress">
                            <span className="skill">Web Accessibility & Usability 
                                <i className="val">
                                    <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                                        <CountUp start={0} end={viewport ? 90 : 0} duration={2} />
                                    </VisibilitySensor>%
                                </i>
                            </span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" style={{width: '90'+'%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">

                        <div className="progress">
                            <span className="skill">JavaScript
                                <i className="val">
                                    <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                                        <CountUp start={0} end={viewport ? 80 : 0} duration={2} />
                                    </VisibilitySensor>%
                                </i>
                            </span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" style={{width: '80'+'%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div className="progress">
                            <span className="skill">React JS
                            <i className="val">
                                <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                                    <CountUp start={0} end={viewport ? 80 : 0} duration={2} />
                                </VisibilitySensor>%
                            </i>
                            </span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" style={{width: '80'+'%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div className="progress">
                            <span className="skill">Storybook React
                            <i className="val">
                                <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                                    <CountUp start={0} end={viewport ? 90 : 0} duration={2} />
                                </VisibilitySensor>%
                            </i>
                            </span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" style={{width: '90'+'%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                        <div className="progress">
                            <span className="skill">Design Thinking 
                                <i className="val">
                                    <VisibilitySensor onChange={onVisibilityChange} delayedCall>
                                        <CountUp start={0} end={viewport ? 90 : 0} duration={2} />
                                    </VisibilitySensor>%
                                </i>
                            </span>
                            <div className="progress-bar-wrap">
                                <div className="progress-bar" role="progressbar" style={{width: '90'+'%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </section>
    )
}

export default Skills
