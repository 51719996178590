const ItemsData =
    [
        {
            "id": 0,
            "name": "Pias Golam Asad",
            "designation": "Senior Business Analyst",
            "Office_name": "Brain Station 23 Limited",
            "image_url" : "assets/img/testimonials/pias.png",
            "testimonial": "Riage proved to be one of the most dedicated professionals through his work at Brain Station 23. He thinks out of the box and he is hard-worker. He also have the skills of problem solving, analytical skill and high understanding level. Riage never refuses you when you ask him for help. Is always capable of adapting to new working environments. Very positive attitude towards work. Riage worked far beyond the call of duty. Absolutely worth recommending."
        },
        {
            "id": 1,
            "name": "Abdullah-Al-Mahedi Sabuj",
            "designation": "Lead Engineer",
            "Office_name": "Brain Station 23 Limited",
            "image_url" : "assets/img/testimonials/mehedi.png",
            "testimonial": "Syed Shah Riage is a very detail orientated Front End Developer. Whenever I stuck with any UI-related problem, I discuss it with him and no matter how complex the problem is, he always comes up with a brilliant and cost-effective solution. I had the pleasure of working with him for the last two years at Brain Station 23 Ltd. I have found him thinking about innovation. Excellent personality with a smiling face."
        },
        {
            "id": 2,
            "name": "Syed Mainul Hasan",
            "designation": "Senior Software Developer",
            "Office_name": "BRED IT (Thailand) Limited",
            "image_url" : "assets/img/testimonials/mainul.png",
            "testimonial": "I worked with Mr. Riage in the same team at Brain Station-23 Ltd. He is at the same time highly talented, passionate, supportive and a great team player. Working with him was fun and for any problem related to his working domain he used to bring it to an impressive solution. He has excellent communication skill and I believe he is a perfect match for any good team worldwide."
        },
        {
            "id": 3,
            "name": "Toriqul Islam Tareq",
            "designation": "Senior Web & Mobile Application Developer",
            "Office_name": "Supreme Supports Pty Limited",
            "image_url" : "assets/img/testimonials/toriqul.png",
            "testimonial": "Creative,enthusiastic & self motivated front end developer."
        }
    ]


export default ItemsData