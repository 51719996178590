import React from 'react'
import Menu from './data' 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

function PortfolioDetails({dataIdNo, closeModal}) {

    const portfolio = Menu[dataIdNo]
    const options = {
        responsive:{
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            600: {
                items: 1,
            },
            1000: {
                items: 1,
            },
        },
        nav: true,
        autoplay: true,
        slideBy: 1,
        dots: false,
        dotsEach: false,
        dotData: false,
        loop: true
    };

    return (
        <div className="modal fade show" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{portfolio.title}</h5>
                        <button onClick={() => closeModal(false)} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <OwlCarousel className='owl-theme' {...options} >
                            {portfolio.image.map((elem) => {
                                const {image_id, image_url} = elem
                                return(
                                    <div className="portfolio-details__img">
                                        <img id={image_id} src={image_url} alt={portfolio.title}/>
                                    </div>
                                )
                            })}
                        </OwlCarousel>
                        <div className="mt-4">
                            <p className="mb-1"><b>Link:</b> <a target="_blank" href={portfolio.link}>{portfolio.link}</a></p>
                            <p className="mb-1"><b>Technology:</b> {portfolio.technology}</p>
                            <p className="mb-1"><b>Technical Responsibility:</b> {portfolio.technical_desc}</p>
                            <p className="mb-0"><b>Description:</b> {portfolio.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PortfolioDetails
