import React, {useState} from 'react'
import './facts.css'

function Facts() {

    return (
        <section id="facts" className="facts section-bg">
            <div className="container">

                <div className="section-title">
                    <h2>Facts</h2>
                    <p> I always value the emotions, thoughts and ❤️ that use to build every pixel of an interface. I am passionate about building excellent UI that improves the lives of those around me. I specialize in creating UI for clients ranging from individuals and small-businesses all the way to large enterprise corporations. I also have sharp eyes on UI/UX design and i love to do that.</p>
                </div>

                <div className="row no-gutters">

                    <div className="col-lg-3 col-md-6" data-aos="fade-up">
                        <div className="fact__item">
                            <div className="icon">
                                <i class="icofont-speed-meter"></i>
                            </div>
                            <h5>FOCUS</h5>
                            <p>We have a 100% commitment to making all our sites the best they can possibly be, no matter what it takes to get there.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay="100">
                        <div className="fact__item">
                            <div className="icon">
                                <i class="icofont-speed-meter"></i>
                            </div>
                            
                            <h5>PASSION</h5>
                            <p>Our desire to produce good work runs deep – that’s what lets us handle every project with fresh energy and enthusiasm.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay="200">
                        <div className="fact__item">
                            <div className="icon">
                                <i class="icofont-bulb-alt"></i>
                            </div>
                            
                            <h5>EMPATHY</h5>
                            <p>While we share our knowledge and experience, we listen hard to understand your business and your needs..</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay="300">
                        <div className="fact__item">
                            <div className="icon">
                                <i class="icofont-rocket-alt-2"></i>
                            </div>
                            
                            <h5>TEAMWORK</h5>
                            <p>We are united with you in the drive to get the best from your project – think of us as extra members of your team with all the skills you need.</p>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}

export default Facts
