import './hero-banner.css'
import { init } from 'ityped';
import React, { useRef, useEffect } from 'react';

function HeroBanner() {

    const textref = useRef();

    useEffect(() => {
        init(textref.current, { showCursor: false, strings: ['UI Engineer', 'Microsoft Certified', 'AEM Front-End Developer'] })
    }, [])

    return (
        <div className="hero-banner-section" id="hero-banner" >
            <div id="hero" className="d-flex flex-column justify-content-center align-items-center" style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/Syed_Shah_Riage-hero-img.jpg'})`}}>
                <div className="hero-container" data-aos="fade-in">
                <h1 className='mb-4'>Syed Shah Riage</h1>
                <p>I'm <span className="typed" ref={textref}></span></p>
                </div>
            </div>
        </div>
    )
}

export default HeroBanner
